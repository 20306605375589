@import url('https://cdn.syncfusion.com/ej2/material.css');

hr {
  height: 1px !important;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 2px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}