@import url('https://fonts.googleapis.com/css2?family=Lilita+One&family=ZCOOL+KuaiLe&display=swap');

body{
  margin: 0;
  padding:0;
  /*font-family: 'Open Sans', sans-serif;*/
  /*font-family: 'Lilita One', cursive;*/
  font-size: 0.8rem;
}

@font-face {
  font-family: "PingFang SC";
  font-weight: 400;
  src: url("assets/font/PingFangSC.ttf");
  font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;